
body{
    height: 100%;
}
.loc-ico
{
  
    width: 20px;
    height: 20px;

}
.vid_container {
    width: 100%;
    height: 100%;
    position: relative;
  }
.hdr-1
{
    background-color: rgb(19 133 228);
    text-align: center;
    height: 77px;
    margin-top: 0px;
    color: white;
    padding-top: 12px;
    margin-bottom: 45px;
    box-shadow: 0 2px 4px rgb(0 0 0 / 15%);
}
.iphone-non
{
    display:none;
}
.element-div
{
    position: relative;
    margin: auto;
    float: inherit;
    text-align: center;
    margin-bottom: 18px;
}
.img-div
{
    width: 100%;
    border-radius: 8px;
    /* box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%); */
    cursor: pointer;
    object-fit: cover !important;
}
.pd-0
{
    padding: 0;
}
.inr-cont
{
    
    margin: auto;
    width: 75%;
    box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
    border-radius: 8px;

}
.spinner-border
{
    margin: auto;
    margin-top: 14px;
}
.cmr-btn
{
    padding: 0px 0px;
    border-radius: 50%;
    background: #fff;
    border: none;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    /* margin-top: -5%; */
    z-index: 999999;
    position: absolute;
    bottom: -28px;
    height: -moz-fit-content;
    height: fit-content;
    width:  80px;
    box-shadow: 0px 0px 10px -3px #2c88ff;
}
.cls-btn
{
    color: rgb(255 255 255 / 58%);
    background: #ffffff00;
    border: none;
    z-index: 999999;
    float: right;
    padding: 0;
    /* position: fixed;
    top: -18px;
    right: 0; */
}

.imgUpload
{
    display: none;
}
.vid_container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    position: fixed;
}
.btn-div-hdr
{
    width: 100%;
    display:flex;
    padding: 4px 10px;
    position: absolute;
    z-index: 99999999;
}
.col-xs-4-2
{
    width: 68%;
    margin-left: 7px;
}
.col-xs-4-3
{
    width: 10%;
}
.col-xs-4-1
{
    width: 18%;
}
.col-xs-12
{
    width:100%
}

.iconloc
{
    background-image: linear-gradient(135deg,rgb(0 169 255), #0050ff);
    padding: 0px 5px;
    color: #fff;
    border-radius: 4px 4px 4px 4px; 
   
    display: flex;
}
.idtxt
{
    background-image: linear-gradient(135deg,rgb(0 169 255), #0050ff);
    padding: 0px 5px;
    color: #fff;
    border-radius: 4px 4px 4px 4px; 
    margin-left:4px;
    font-size: 14px;
    width: fit-content;
   
}
.idtxt2
{
    background-image: linear-gradient(135deg,rgb(0 169 255), #0050ff);
    padding: 0px 9px;
    color: #fff;
    border-radius: 4px 4px 4px 4px;
    text-transform: uppercase;
    width: fit-content;
position: relative;
margin: auto;
    font-size: 14px;
  
}
.mrad
{
    margin:auto
}
.btn-div
{
    width: 100%;
    position: fixed;
    justify-content: center;
    bottom: 48px;
    height: 100%;
    margin: auto;
}
.cl-icon-2
{
    position: absolute;
    float: right;
    right: -5px;
    background: #ffffff;
  
    border-radius: 24px;
    box-shadow: 0px 0px 10px -3px #2c88ff;

}
.img-land
{
    margin-left: 2.1em;
}
.close-icon
{
    color: white;
    font-size: 43px;
    padding-bottom: 10px;
}
.close-nw
{
    width: 100%;
}

@media screen and (orientation: portrait) {
    .btn-div
{
    width: 100%;
   
    bottom: 48px;
}
.landsp-img
{
    display: flex;
    justify-content: center;
}

#toggleFullScreenButton {
   
    opacity: 1.8;
    display: block;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    bottom: -22px;
    right: 33px;
    width: fit-content;
   
    background: #fff;
    padding: 8px 8px;
    box-shadow: 0px 0px 18px -3px #2c88ff;

  }
  #toggleFullScreenButton[aria-pressed='true'] {
    position: fixed;
    z-index: 2;
    bottom: 30px;
    
  }

.locationdiv
{

    grid-gap: 4px;
    padding: 2px 8px;
}
.iconloc
{
    background-image: linear-gradient(135deg,rgb(0 169 255), #0050ff);
    padding: 2px 4px;
    color: white;
    border-radius: 4px 4px 4px 4px;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    margin-left: 4px;
    float: left;
    font-size: 14px;
    margin-top: 8px;
}

}
@media screen and (orientation: landscape) {
    .btn-div
{
    width: 20%;
   right: 0;
    bottom: 0px;
}
.cmr-btn {
  
    left: calc(55% - 30px);
    top: calc(40% - 50%);
    width:80px;

}
#toggleFullScreenButton {
    opacity: 1.8;
    display: block;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    bottom: 38px;
    /* display: none; */
    right: 40px;
    padding: 6px 6px;
    width: fit-content;
  
    background-color: #fff;
  }
  #toggleFullScreenButton[aria-pressed='true'] {
    position: fixed;
    z-index: 2;
    bottom: 38px;
    
  }
  
.landsp-img
{
    display: none;
    justify-content: center;
}
.locationdiv
{
   display: flex;
    grid-gap: 4px;
    padding: 2px 8px;
}
.iconloc
{
    background-image: linear-gradient(135deg,rgb(0 169 255), #0050ff);
    padding: 2px 4px;
    color: white;
    border-radius: 4px 4px 4px 4px; 
    display: flex;
    width:fit-content;
    margin-top: 8px;
    font-size: 14px;
    margin-left: 4px;
}
.cl-icon-2 {
   
    right: 36px;
}

}